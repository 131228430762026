.exercise-control {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-left: 0.1px solid;
  padding-right: 0.5rem;
  padding-left: 0.5rem !important;
  padding-top: 0.5rem !important;
  position: relative;
}

.controls-main-section {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 3.5rem;
}

.controls-hand-selector {
  justify-content: center;
}

.metronome-controls-main-section {
  display: flex;
  margin-bottom: 0.4rem;
}

.controls-main-section object {
  height: 100%;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.config-selector {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.controls-container {
  padding-right: 0;
  padding-left: 0;
}

.controls-grid-root {
  margin-left: 0;
  margin-top: 0;
  width: 100%;
}

.bar-selector {
  margin-bottom: 1rem;
}

.rest-of-controls-container {
  display: flex;
  justify-content: space-around;
}

.text-icon-button {
  display: flex;
  flex-direction: column;
}

.text-icon-button_text {
  font-size: 0.7rem;
  text-transform: uppercase;
}

.more-options {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0 !important;
  padding-left: 0.1rem !important;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
}

.more-options a {
  text-decoration: none;
}

.max-50 {
  max-width: 50%;
}
